@use 'variables.module' as *;
@use 'font.mixins' as *;

// This file defines each of the typography styles used in the application, it is equivalent to the typography file in figma

// @todo: This headline is defined as h2 in figma, but it's defined as h3 in the variables file
.TypographyHeadline2 {
  @include FontSecondarySemiBold;
  font-size: var(--font-size-headline-h3-mobile);
  line-height: var(--line-height-headline-h3-mobile);

  @media all and (min-width: $laptop) {
    font-size: var(--font-size-headline-h3);
    line-height: var(--line-height-headline-h3);
  }
}
// @todo: This headline is defined as h2 in figma, but it's defined as h3 in the variables file
.TypographyHeadline3 {
  @include FontSecondaryRegular;
  font-size: var(--font-size-headline-h3-mobile);
  line-height: var(--line-height-headline-h3-mobile);

  @media all and (min-width: $laptop) {
    font-size: var(--font-size-headline-h4);
    line-height: var(--line-height-headline-h4);
  }
}

.TypographyCopy {
  @include FontPrimaryRegular;
  font-size: var(--font-size-text);
  line-height: 22px; // @todo: This variable is missing in the variables file

  @media all and (min-width: $laptop) {
    font-size: var(--font-size-text);
    line-height: 22px; // @todo: This variable is missing in the variables file
  }
}

.TypographyCopySmall {
  @include FontPrimaryRegular;
  font-size: var(--font-size-text-small);
  line-height: 22px; // @todo: This variable is missing in the variables file
}

// In figma defined as Copy-Hervorgehoben
.TypographyCopySmallBold {
  @include FontPrimaryBold;
  font-size: var(--font-size-text);
  line-height: 22px; // @todo: This variable is missing in the variables file
}

.TypographyLabel {
  @include FontSecondaryRegular;
  font-size: var(--font-size-text-small);
  line-height: 14px; // @todo: This variable is missing in the variables file
}

.TypographyCTA {
  @include FontSecondaryRegular; // @todo: In figma this is medium instead regular but we don't have a medium font weight defined
  font-size: 20px; // @todo: This variable is missing in the variables file
  line-height: 24px; // @todo: This variable is missing in the variables file
}

.TypographyCTASmall {
  @include FontSecondaryRegular; // @todo: In figma this is medium instead regular but we don't have a medium font weight defined
  font-size: 16px; // @todo: This variable is missing in the variables file
  line-height: 20px; // @todo: This variable is missing in the variables file
}
