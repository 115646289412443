@use 'sass:color';

:root {
  --test-color: green;
}

:root .mts-theme {
  /*
  Primary: E2001A
  Primary-VAR: FFCC00
  Primary-Background: F9CCD1
  Secondary: 818A8F
  Secondary-VAR: 818A8F
  Secondary-Background: E1E4E6
  Near-Black: 000000
  Grey-Dark: 595959
  Grey-Light: BDBDBD
  # Grey-Light New: E2E4E6

  pipigelb: #FDCC17
  Grey-Background: B2B2B2
  White: FFFFFF
   */
  /*********************************************************************/
  /* NAMED TENANT COLORS                                               */
  /* This color names are configured by the tenant's CI/CD             */
  /* and should be used to define the plain color values in CSS format */
  /*********************************************************************/
  --theme-primary: #E2001A;
  --theme-primary-light: #E2001A24; // 14% opacity
  //--theme-secondary: #818a8f;
  --theme-secondary: #595959;
  //--theme-secondary: var(--theme-primary);
  --theme-white: #FFFFFF;
  --theme-black: #000000;
  --theme-grey: #BDBDBD;
  --theme-grey-light: #E1E4E6;
  --theme-secondary-background: #595959;
  --theme-secondary-background80: #595959CC; // 80% opacity
  --theme-secondary-background66: #595959A8; // 66% opacity
  --theme-secondary-background50: #59595980; // 50% opacity
  --theme-secondary-background07: #59595912; // 7% opacity
  --theme-background: var(--theme-white);

  /*********************************************************************/
  /* Use only NAMED THEME COLORS to define next variable values        */
  /*********************************************************************/
  /* MAIN COLORS */
  --primary-color: var(--theme-primary);
  --primary-color-light: var(--theme-primary-light);
  --primary-hover-color: var(--theme-primary);
  --primary-active-color: var(--primary-color);
  --secondary-color: var(--theme-secondary);
  --secondary-background-color: var(--theme-secondary-background);
  --secondary-contrast-font-color: var(--theme-white);
  --white: var(--theme-white);
  --black: var(--theme-black);

  /*******************************************************/
  /* Use only MAIN COLORS to define next variable values */
  /*******************************************************/
  /* BACKGROUNDS */
  --app-background-color: var(--theme-background);
  --background-light: var(--theme-grey-light);
  --background-dark: var(--theme-secondary-background);
  --input-background: var(--theme-background);
  --hint-background-color: var(--theme-primary-light);

  /* FONT COLORS */
  --font-color-light: var(--theme-secondary-background);
  --font-color-medium: var(--theme-secondary-background80);
  --font-color-disabled: var(--theme-grey);

  /* ERRORS */
  --color-error: #E7392F;
  --color-error-background: #FDEBEA;

  /* WARNINGS */
  --color-warning-background: var(--theme-primary-light);

  /* SUCCESS */
  --color-success: #27AE60;

  /* BORDERS */
  --border-light-color: #BDBDBD;
  --border-disabled-color: #BDBDBD;

  /* Rounded corners */
  --container-border-radius: 0;
  --input-button-border-radius: 0;
  --input-checkbox-border-radius: 0;
  --input-text-border-radius: 0;
  --global-search-suggestions-border-radius: 0;
  --global-search-bar-header-border-radius-mobile: 0;
  --category-card-border-radius: 0;

  /* SHADOWS */
  --overlay-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  --overlay-border-radius: 0;

  /* FONTS */
  --font-primary-regular-family: "Inter";
  --font-primary-regular-weight: 400;
  --font-primary-regular-style: normal;

  --font-primary-semi-bold-family: "Inter";
  --font-primary-semi-bold-weight: 600;
  --font-primary-semi-bold-style: normal;

  --font-secondary-light-family: "Inter";
  --font-secondary-light-weight: 300;
  --font-secondary-light-style: normal;

  --font-secondary-regular-family: "Inter";
  --font-secondary-regular-weight: 400;
  --font-secondary-regular-style: normal;

  --font-secondary-regular-italic-family: "Inter";
  --font-secondary-regular-italic-weight: 400;
  --font-secondary-regular-italic-style: italic;

  --font-secondary-semi-bold-family: "Inter";
  --font-secondary-semi-bold-weight: 600;
  --font-secondary-semi-bold-style: normal;

  /* FONT-SIZES */
  --font-size-steps: 7px;
  // Mobile sizes
  --font-size-text-mobile: 17px;
  --font-size-text-small-mobile: 14px;
  --font-size-headline-h1-mobile: calc(4 * var(--font-size-steps));
  --font-size-headline-h2-mobile: calc(3.5 * var(--font-size-steps));
  --font-size-headline-h3-mobile: calc(2.5 * var(--font-size-steps));
  --font-size-headline-h4-mobile: calc(2.1 * var(--font-size-steps));
  --font-size-text-small-title-mobile: var(--font-size-headline-h4-mobile);
  // Mobile line heights
  --line-height-headline-h1-mobile: calc(4.7 * var(--font-size-steps));
  --line-height-headline-h2-mobile: calc(4.1 * var(--font-size-steps));
  --line-height-headline-h3-mobile: calc(3 * var(--font-size-steps));
  --line-height-headline-h4-mobile: calc(2.5 * var(--font-size-steps));

  // Non-mobile sizes
  --font-size-text: 15px;
  --font-size-text-small: 13px;
  --font-size-headline-h1: calc(4 * var(--font-size-steps));
  --font-size-headline-h2: calc(3 * var(--font-size-steps));
  --font-size-headline-h3: calc(2.5 * var(--font-size-steps));
  --font-size-headline-h4: calc(2 * var(--font-size-steps));
  --font-size-text-small-title: var(--font-size-headline-h4);
  // Non-mobile line heights
  --line-height-headline-h1: calc(4.5 * var(--font-size-steps));
  --line-height-headline-h2: calc(3.5 * var(--font-size-steps));
  --line-height-headline-h3: calc(3 * var(--font-size-steps));
  --line-height-headline-h4: calc(2.5 * var(--font-size-steps));

  /* TRANSITIONS */
  --text-hover-transition: color 0.2s ease, fill 0.2s ease;

  /* SPACING */
  --spacing-unit: 4px;

  @media all and (min-width: 1024px) {
    --spacing-unit: 7px;
  }


  /** OLD */
  --grey-typo: #595959;
  --spacing-steps: 4px;

  @media all and (min-width: 1024px) {
    --spacing-steps: 7px;
  }

  /*********************************************************************/
  /* COMPONENTS should be configured only in edge cases                */
  /*********************************************************************/
  /* COMPONENTS */
  /** Global search bar **/
  --global-search-bar-background: var(--background-light);
  --global-search-bar-radius: 0;
  --global-search-bar-padding-left: 16px;
  --global-search-bar-padding-right: 16px;
  --global-search-bar-padding-top-down: 8px;
  --global-search-bar-input-focus-border-radius: var(--input-text-border-radius);
  --global-search-button-radius: 0;
  --global-search-button-radius-mobile: 0;
  --global-button-side-length: 40px;

  /** Search header **/
  --search-header-background-color:var(--secondary-background-color);
  --search-header-font-color: var(--white);

  /** Search page **/
  --search-page-filter-header-font-size: var(--font-size-headline-h4);
  --search-page-filter-box-header-divider: 1px solid var(--border-light-color);

  /** Homepage **/
  --homepage-header-font-color: var(--black);
  --homepage-header-height: 630px;

  /** Page header **/
  --page-header-height: 77px;
  --page-header-background-color: var(--white);
  --page-header-height-desktop: 77px;
  --page-header-font-color: var(--black);
  --page-header-cart-color: var(--primary-color);
  --page-header-cart-font-color-contrast: var(--white);

  /** Page footerv**/
  --page-footer-background-color: var(--theme-grey);
  --page-footer-font-color: var(--black);

  /** Configurator **/
  --configurator-header-background-color: var(--theme-grey-light);
  --configurator-header-font-color: var(--font-color);
  --configurator-section-background-color: var(--theme-white);
  --configurator-section-form-background-color: var(--theme-grey-light);
  --configurator-section-price-background-color: transparent;
  --configurator-section-specs-background-color: var(--theme-grey-light);
  --configurator-header-font-size: var(--font-size-headline-h4);
  --configurator-title-font-size: var(--font-size-headline-h2);
  --configurator-subtitle-font-size: var(--font-size-headline-h2);
  --configurator-font-size: var(--font-size-text-small-title);
  --configurator-cta-button-background-color: var(--primary-color);
  --configurator-cta-button-font-color: var(--white);
  --configurator-cta-button-border-color: var(--primary-color);

  /** Basket **/
  --basket-billing-title-font-size: var(--font-size-headline-h1);
  --basket-billing-title-font-size-mobile: var(--font-size-headline-h4);
  --basket-summary-font-size: var(--font-size-headline-h4);
  --basket-summary-font-big: var(--font-size-headline-h3);

  /** Product cards **/
  --product-card-header-background-color: var(--theme-grey-light);
  --product-card-header-font-size: var(--font-size-headline-h3);

  /** Main navigation **/
  --navigation-container-background-color: var(--white);
  --navigation-header-font-color: #FDCC17; // default: var(--primary-color)
  --navigation-background-color: var(--theme-secondary); // default: var(--black)

  /** Category overview **/
  --category-overview-container-background-color: var(--app-background-color);
  --category-overview-card-header-border-bottom-color: var(transparent);
  --category-overview-card-header-text-color: var(--black);

  /** Sidebar **/
  --page-sidebar-background-color: var(--white);
  --page-sidebar-title-font-size: var(--font-size-headline-h3);

  /** Product page **/
  --product-page-cta-button-background-color: var(--primary-color);
  --product-page-cta-button-font-color: var(--white);
  --product-page-cta-button-border-color: var(--primary-color);

  /** Buttons **/
  --button-font-size: var(--font-size-headline-h4);

  /** Emails **/
  --email-background-color: var(--white);
  --email-body-color: var(--white);
  --email-text-color: var(--theme-secondary-background);
  --email-footer-background-color: var(--theme-secondary-background);
  --email-footer-text-color: var(--white);
  --email-delimiter-color: #73787D;
  --email-button-text-color: var(--white);
  --email-button-background-color: var(--primary-color);
  --email-table-row-color: #EFF0F0;

  /** Keycloak **/
  --keycloak-loader-color: var(--primary-color);

  --keycloak-header-background-color: rgba(234, 235, 235, 1);
  --keycloak-header-font-color: var(--black);

  --keycloak-footer-background-color: #343434;
  --keycloak-footer-font-color: #ffffff;
}
